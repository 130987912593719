<template>
    <div class="navBar">
        <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#e9f1ff"
        text-color="#8799aa"
        active-text-color="#2a7aff">
            <el-menu-item index="2" @click="goPath('/cardListPage')">
                <i class="el-icon-menu"></i>
                <span slot="title">卡牌</span>
            </el-menu-item>
            <el-menu-item index="3" @click="goPath('/groupListPage')">
                <i class="el-icon-document"></i>
                <span slot="title">卡组</span>
            </el-menu-item>
            <!-- <el-menu-item index="4">
                <i class="el-icon-setting"></i>
                <span slot="title">用户管理</span>
            </el-menu-item> -->
        </el-menu>
    </div>
</template>
<script>
export default {
    name:'NavBar',
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        goPath(e){
            this.$router.push({path:e,query:{}})
        }
    },
}
</script>
<style lang="scss">
.navBar {
    height:100%;
    .el-menu-vertical-demo {
        height:100%;
    }
}
</style>
