<template>
    <div class="index-warp">
        <el-container>
            <el-header>后台管理系统<div class="logout" @click="logout">登出</div></el-header>
            <el-container class="index-content">
                <el-aside width="200px">
                    <Nav />
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import Nav from '../components/nav'
import {logout} from '../api/index'
export default {
    name:'IndexPage',
    components:{Nav},
    methods: {
        logout(){
            logout().then(res => {
                console.log(res)
                this.$message({
                    message: '登出成功',
                    type: 'success',
                    duration: 3 * 1000
                })
                window.location.hash= '/login'
            })
        }
    },
}
</script>
<style lang="scss">
    .index-warp {
        width: 100%;
        height:100%;
        .el-container{
            width: 100%;
            height:100%;
            &.index-content {
                height: calc(100% - 60px);
            }
            .el-header {
                line-height: 60px;
                font-size: 20px;
                font-weight: 700;
                color: #2a7aff;
                background: #e9f1ff;
                .logout {
                    color: #2a7aff;
                    font-size: 14px;
                    position: absolute;
                    right: 20px;
                    top: 5px;
                    cursor: pointer;
                }
            }
        }
    }
</style>



