<template>
    <div class="edit-group">
        <el-dialog
            title="编辑卡组卡牌"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal= "false"
            :before-close="handleClose">
            <div class="table-box dialog-table">
                <div class="btn-box">
                    <el-button type="primary" @click="addCard">添加卡片</el-button>
                </div>
                <el-table :data="tableData" size="small" max-height="450">
                    <el-table-column prop="name" label="卡牌名称"></el-table-column>
                    <el-table-column prop="front_img_url" label="卡牌图片">
                        <template slot-scope="scope">
                            <img class="table-pic" :src="scope.row.front_img_url" />
                            <img v-if="scope.row.bak_img_url" class="table-pic" :src="scope.row.bak_img_url" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="卡牌id"> </el-table-column>
                    <el-table-column fixed="right" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="dele(scope)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {detailGroup,updateGroup} from '../api/index'
export default {
    name:'EditCard',
    props:['showCardDetail','selectData','nowCardList'],
    data() {
        return {
            dialogVisible:false,
            id:'',
            tableData:[]
        }
    },
    watch:{
        showCardDetail:{
            handler(newV){
                this.dialogVisible = newV
                if(newV) {
                    this.detailGroup(this.selectData.id)
                }
            },
            immediate: true
        },
        selectData:{
            handler(newV){
                this.id = newV.id
            },
            immediate: true,
            deep:true
        },
        nowCardList:{
            handler(newV){
                if(newV) {
                    this.tableData = newV
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeDetail',false)
        },
        submit(){
            this.$confirm('确认提交？').then(() => {
                console.log(this.tableData)
                var upData = {
                    id:this.selectData.id,
                    name: this.selectData.name,
                    deck_sort: this.selectData.deck_sort,
                    cover_img_url: this.selectData.cover_img_url,
                    desc: this.selectData.desc,
                    cards:[]
                }
                for(var i=0;i<this.tableData.length;i++) {
                    upData.cards.push({id:this.tableData[i].id})
                }
                updateGroup(upData).then(res => {
                    if(res.message == 'success') {
                        this.$message('添加成功');
                        this.$emit('closeDetail',true)
                    }
                })
            }).catch(() => {});
        },
        detailGroup(e){
            detailGroup({id:e}).then(res => {
                console.log(res)
                this.tableData = res.data.cards||[]
            })
        },
        cancel(){
            this.$emit('closeDetail',false)
        },
        addCard(){
            this.$emit('openCardList',this.tableData)
        },
        dele(e) {
            console.log(e)
            this.tableData.splice(e.$index,1)
        }
    },
}
</script>
<style lang="scss">
</style>
