import VueRouter from 'vue-router'
import Vue from 'vue'

import LoginPage from '../views/login'
import IndexPage from '../views/indexPage'
import CardListPage from '../views/cardListPage'
import GroupListPage from '../views/groupListPage'

Vue.use(VueRouter)
const routes = [{
    path:'/login',
    component:LoginPage
},{
    path:'/indexPage',
    component:IndexPage,
    children:[{
        path:'/cardListPage',
        component:CardListPage
    },{
        path:'/groupListPage',
        component:GroupListPage
    }]
}];
const router = new VueRouter({
    routes
})
export default router