<template>
    <div class="edit-group">
        <el-dialog
            title="选择卡牌"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal= "false"
            :destroy-on-close="true"
            :before-close="handleClose">
            <div class="table-box">
                <div class="searchBar">
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="卡牌名称">
                            <el-input v-model="formInline.name" placeholder="卡牌名称"></el-input>
                        </el-form-item>
                        <el-form-item label="卡牌类型 ">
                            <el-select v-model="formInline.card_type" placeholder="卡牌类型 ">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="单图片卡牌" value="1"></el-option>
                                <el-option label="双图片卡牌" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit" style="margin-right:6px;">查询</el-button>
                            <el-button @click="onClear">清除</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="table-box">
                    <el-table :data="tableData" size="small" max-height="350">
                        <el-table-column prop="name" label="卡牌名称"></el-table-column>
                        <el-table-column prop="front_img_url" label="卡牌图片">
                            <template slot-scope="scope">
                                <img class="table-pic" :src="scope.row.front_img_url" />
                                <img v-if="scope.row.bak_img_url" class="table-pic" :src="scope.row.bak_img_url" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="id" label="卡牌id"> </el-table-column>
                        <el-table-column fixed="right" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button v-if="checkChoose(scope.row.id)" @click="choose(scope)" type="text" size="small">选择</el-button>
                                <el-button v-if="!checkChoose(scope.row.id)" @click="chooseback(scope)" type="text" size="small">取消选择</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination-box">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pagination.page"
                            :page-sizes="[10, 20, 30, 100]"
                            :page-size="pagination.page_size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {cardList} from '../api/index'
export default {
    name:'CheckCard',
    props:{
        showCardList:{
            type:Boolean,
            default:false
        },
        nowCardList:{
            type:Array,
            default:()=>{return []}
        },
        selectGroupType:{
            type:String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible:false,
            tableData:[],
            formInline: {
                name: '',
                card_type: ''
            },
            pagination:{
                page: 1,
                page_size: 10,
            },
            total:0,
            initList:[]
        }
    },
    watch:{
        showCardList:{
            handler(newV){
                this.dialogVisible = newV;
                this.initList = JSON.parse(JSON.stringify(this.nowCardList))
            },
            immediate: true
        },
        nowCardList:{
            handler(newV){
                this.initList = JSON.parse(JSON.stringify(newV));
            },
            immediate: true
        },
        selectGroupType:{
            handler(newV){
                console.log(newV)
                this.formInline.card_type = newV
                this.onSubmit()
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pagination.page_size = val;
            this.cardList();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pagination.page = val;
            this.cardList();
        },
        handleClose() {
            this.$emit('closeList',false)
        },
        onSubmit(){
            this.pagination.page = 1;
            this.cardList();
        },
        onClear(){
            this.formInline = {
                name: '',
                card_type: ''
            }
        },
        submit(){
            this.$emit('closeList',this.initList)
        },
        cardList(){
            cardList({...this.formInline,...this.pagination}).then(res => {
                console.log(res)
                this.tableData = res.data.data||[]
                this.total = res.data.total;
            })
        },
        checkChoose(e){
            for(var i=0;i<this.initList.length;i++) {
                if(e==this.initList[i].id){
                    return false
                }
            }
            return true
        },
        cancel(){
            this.$emit('closeList',false)
        },
        choose(e){
            this.initList.push(e.row)
        },
        chooseback(e) {
            this.initList = this.initList.filter(res=>{
                return res.id != e.row.id
            })
        }
    },
}
</script>
<style lang="scss">
</style>
