import request from '../utils/request'

let root = '/server_zc'

export function login(data) {
    return request({
        url: root+'/user/login',
        method: 'post',
        data
    })
}
export function logout(data) {
    return request({
        url: root+'/user/logout',
        method: 'post',
        data
    })
}


//卡牌列表
export function cardList(data) {
    return request({
        url: root+'/card/list',
        method: 'post',
        data
    })
}

//新增卡牌
export function addCard(data) {
    return request({
        url: root+'/card/create',
        method: 'post',
        data
    })
}
//编辑卡牌
export function editCard(data) {
    return request({
        url: root+'/cards/update',
        method: 'post',
        data
    })
}
//删除卡牌
export function deleCard(data) {
    return request({
        url: root+'/cards/delete',
        method: 'post',
        data
    })
}

//卡组列表
export function groupList(data) {
    return request({
        url: root+'/deck/list',
        method: 'post',
        data
    })
}
//创建卡组
export function createGroup(data) {
    return request({
        url: root+'/deck/create',
        method: 'post',
        data
    })
}
//更新卡组
export function updateGroup(data) {
    return request({
        url: root+'/deck/update',
        method: 'post',
        data
    })
}
//删除卡组
export function deleteGroup(data) {
    return request({
        url: root+'/deck/delete',
        method: 'post',
        data
    })
}
//卡组详情
export function detailGroup(data) {
    return request({
        url: root+'/deck/detail',
        method: 'post',
        data
    })
}