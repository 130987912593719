<template>
    <div class="edit-card">
        <el-dialog
            title="编辑卡片"
            :visible.sync="dialogVisible"
            width="600"
            :before-close="handleClose">
            <div>
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="卡片名称">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="卡片类型">
                        <el-select v-model="form.card_type" placeholder="请选择卡片类型">
                        <el-option label="单图片卡牌" value="1"></el-option>
                        <el-option label="双图片卡牌" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="前面图片url">
                        <el-input v-model="form.front_img_url"></el-input>
                    </el-form-item>
                    <el-form-item label="后面图片url">
                        <el-input v-model="form.bak_img_url"></el-input>
                    </el-form-item>
                    <el-form-item label="卡牌描述">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {addCard,editCard} from '../api/index'
export default {
    name:'EditCard',
    props:['showCardDetail','formData'],
    data() {
        return {
            form:{
                name:'',
                card_type:'',
                front_img_url:'',
                bak_img_url:'',
                desc:'',
                id:''
            },
            dialogVisible:false,
            
        }
    },
    watch:{
        showCardDetail:{
            handler(newV){
                this.dialogVisible = newV
            },
            immediate: true
        },
        formData:{
            handler(newV){
                if(!newV.length){
                    this.form = {
                        name:'',
                        card_type:'',
                        front_img_url:'',
                        bak_img_url:'',
                        desc:'',
                        id:''
                    }
                }
                this.form = {...this.form,...newV}
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeDetail',false)
        },
        submit(){
            this.$confirm('确认提交？').then(() => {
                if(this.form.id){
                    editCard(this.form).then(res => {
                        console.log(res)
                        this.$message('更新成功');
                        this.$emit('closeDetail',true)
                    })
                }else {
                    delete this.form.id
                    addCard(this.form).then(res => {
                        console.log(res)
                        this.$message('新增成功');
                        this.$emit('closeDetail',true)
                    })
                }
            }).catch(() => {});
        },
        cancel(){
            this.$emit('closeDetail',false)
        }
    },
}
</script>
<style lang="scss">
</style>
