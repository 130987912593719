import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let token = localStorage.getItem('token')
    if(token) {
        config.headers['Token'] = token
    }
    // config.headers['X-Token'] = getToken()
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.message !== 'success') {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    if(error.request.status == 400) {
        Message({
            message: error.response.data.message,
            type: 'error',
            duration: 5 * 1000
        })
    }else if(error.request.status == 401) {
        Message({
            message: '登录验证失败，请重新登陆',
            type: 'error',
            duration: 3 * 1000,
            onClose:() => {
                window.location.hash= '/login'
            }
        })
    }else {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
    }
    return Promise.reject(error)
  }
)

export default service