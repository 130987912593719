<template>
    <div class="search-page">
        <div class="searchBar">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="卡牌名称">
                    <el-input v-model="formInline.name" placeholder="卡牌名称"></el-input>
                </el-form-item>
                <el-form-item label="卡牌类型 ">
                    <el-select v-model="formInline.card_type" placeholder="卡牌类型 ">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="单图片卡牌" value="1"></el-option>
                        <el-option label="双图片卡牌" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit" style="margin-right:6px;">查询</el-button>
                    <el-button @click="onClear">清除</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button type="primary" @click="addCard">新增卡片</el-button>
        </div>
        <div class="table-box">
            <el-table :data="tableData" size="small" >
                <el-table-column prop="name" label="卡牌名称"></el-table-column>
                <el-table-column prop="front_img_url" label="卡牌图片">
                    <template slot-scope="scope">
                        <img class="table-pic" :src="scope.row.front_img_url" />
                        <img v-if="scope.row.bak_img_url" class="table-pic" :src="scope.row.bak_img_url" />
                    </template>
                </el-table-column>
                <el-table-column prop="card_type" label="卡牌类型">
                    <template slot-scope="scope">
                        {{scope.row.card_type=='1'?'单图片卡牌':'双图片卡牌'}}
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="卡牌id"> </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button @click="changeCard(scope)" type="text" size="small">编辑</el-button>
                        <el-button @click="dele(scope)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="[10, 20, 30, 100]"
                    :page-size="pagination.page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <editCard :showCardDetail="showCardDetail" :formData="formData" @closeDetail="closeDetail" />
        
    </div>
</template>
<script>

import {cardList,deleCard} from '../api/index'
import editCard from '../components/editCard'
export default {
    name:'CardListPage',
    components:{editCard},
    data() {
        return {
            formInline: {
                name: '',
                card_type: ''
            },
            pagination:{
                page: 1,
                page_size: 10,
            },
            total:0,
            tableData: [],
            showCardDetail:false,
            formData:{}
        }
    },
    created() {
        this.cardList();
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pagination.page_size = val;
            this.onSubmit();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pagination.page = val;
            this.cardList();
        },
        onSubmit() {
            this.pagination.page = 1
            this.cardList();
        },
        onClear(){
            this.formInline = {
                name: '',
                card_type: ''
            }
        },
        indexMethod(index) {
            return index * 2;
        },
        cardList(){
            cardList({...this.formInline,...this.pagination}).then(res => {
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        addCard(){
            this.showCardDetail = true
            this.formData = {}
        },
        closeDetail(e){
            if(e) {
                this.cardList();
            }
            this.showCardDetail = false
        },
        changeCard(e){
            this.showCardDetail = true
            this.formData = e.row
        },
        dele(e){
            this.$confirm('确认删除？').then(() => {
                deleCard({id:e.row.id}).then(res => {
                    if(res.message == 'success' ){
                        this.$message('删除成功');
                        this.cardList();
                    }
                })
            })
        }
    },
}
</script>
<style lang="scss">
.search-page {
    width: 100%;
    .searchBar {
        min-height: 60px;
    }
    .btn-box {
        height: 40px;
        margin-bottom: 10px;
    }
    .table-box {
        .el-table {
            box-sizing: border-box;
            thead {
                color:#909399;
                th {
                    background: #f6f6f6;
                }
            }
            .table-pic {
                height: 48px;
                margin-right: 10px;
            }
        }
        .pagination-box{
            height: 40px;
            text-align: right;
        }
    }
}
    .demo-form-inline {
        margin-top: 20px;
    }
</style>